import { api } from "./API_CONST";

export enum StateViewCategory {
  default = "default",
  user_custom = "user_custom",
}
export enum TaskExpandState {
  expand = "expand",
  collapse = "collapse",
}

export enum TaskProgressState {
  undone = "undone",
  done = "done",
}

export type StateView = {
  id: string;
  name: string;
  category: StateViewCategory;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  rootNodeId: string;
};

export type TaskState = {
  id: string;
  expandState: TaskExpandState;
  progressState: TaskProgressState;
  createdAt: Date;
  updatedAt: Date;
  taskNodeId: string;
  stateViewId: string;
};

export interface TaskWithState {
  id: string;
  parentId: string;
  content: string;
  stateId: boolean;
  authorId: string;
  createdAt: string;
  state: TaskState;
}

interface StateViewPagingResponse {
  rows: StateView[];
  totalRows: number;
}

async function paging(from: number, to: number, search: string) {
  const response = await api.get(
    `/state-view/paging?from=${from}&to=${to}&search=${search}`,
  );
  const result = response.data as StateViewPagingResponse;
  return result;
}

async function get(id: string) {
  const response = await api.get(`/state-view/${id}`);
  const result = response.data as StateView;
  return result;
}

async function getNode(parentId: string, stateViewId: string) {
  const response = await api.get(
    `/state-view/node/${parentId}?/stateViewId=${stateViewId}`,
  );
  const result = response.data as TaskWithState;
  return result;
}

async function getChildren(parentId: string, stateViewId: string) {
  const response = await api.get(
    `/state-view/children/${parentId}?/stateViewId=${stateViewId}`,
  );
  const result = response.data as TaskWithState[];
  return result;
}

async function update(ID: string, completed: boolean) {
  return api.patch("/task/" + ID, { completed });
}

// async function update(ID: string, name: string) {
//   return api.patch('/tasks/' + ID, { name: name });
// }

async function remove(ID: string) {
  return api.delete("/task/" + ID);
}

const StateViewAPI = {
  get,
  paging,
  getNode,
  getChildren,
  update,
  remove,
};

export default StateViewAPI;
