import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { HelmetProvider } from "react-helmet-async";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// TODO: maybe detect & support more locale
import de from "date-fns/locale/de";

import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    // <React.StrictMode>
    <HelmetProvider>
        <LocalizationProvider adapter={AdapterDateFns} adapterLocale={de}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </LocalizationProvider>
    </HelmetProvider>
    // </React.StrictMode>
);
