import { Suspense, ElementType, lazy, ComponentType } from "react";
const loadable = (Component: ElementType) => (props: any) => {
    return (
        <Suspense>
            <Component {...props} />
        </Suspense>
    );
};

export const lazyLoad = (
    factory: () => Promise<{
        default: ComponentType<any>;
    }>
) => {
    const Component = loadable(lazy(factory));
    return <Component />;
};
