import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getGoogleUrl } from "utils/getGoogleUrl";
import { getTest } from "api/Test";
import { Box, Button, Paper } from "@mui/material";

function LoginPage() {
  const navigate = useNavigate();
  const authContextValue = useAuth();
  const location = useLocation();
  const from = ((location.state as any)?.from?.pathname as string) || "/";

  console.log("authContextValue?.currentUser", authContextValue?.currentUser);

  // when Oauth complete, gg will "redirect" to this page.
  // 1) the app is reset and this equal to open a new tab at this page.

  useEffect(() => {
    if (authContextValue?.currentUser !== null) {
      navigate("/");
    }
  }, [authContextValue?.currentUser, navigate]);

  const onClickGoogleLogin = () => {
    const url = getGoogleUrl(from);
    // window.console.log("getGoogleUrl", url);
    window.open(url, "_self");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper
        sx={{
          padding: "80px 100px",
          // fontSize: "35px",
        }}
      >
        <Button
          sx={{
            minWidth: "500px",
            minHeight: "80px",
            fontSize: "20px",
          }}
          variant="contained"
          onClick={() => {
            onClickGoogleLogin();
          }}
        >
          SignIn with Google
        </Button>
      </Paper>
    </Box>
  );
}

export default LoginPage;
