import { useMemo, ReactNode, useState } from "react";
// @mui
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";

import palette from "./palette";
import { ThemeMode } from "./types";
// import typography from "./typography";
// import breakpoints from "./breakpoints";

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export declare type ThemeDirection = "rtl" | "ltr";

export default function ThemeProvider({ children }: Props) {
  const [isLightMode, setIsLightMode] = useState<boolean>(true);
  const [themeDirection, setThemeDirection] = useState<ThemeDirection>("rtl");

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLightMode ? palette.light : palette.dark,
      // typography,
      // breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      // shadows: isLightMode ? shadows.light : shadows.dark,
      // customShadows: isLightMode ? customShadows.light : customShadows.dark,
    }),
    [isLightMode, themeDirection],
  );

  const theme = createTheme(themeOptions);
  // theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        {/* <ChartStyle /> */}
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
