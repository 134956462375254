import { Link } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { Box, Button, Paper, Typography } from "@mui/material";
import useTranslation from "hooks/useTranslation";

export const Header = () => {
    const authContextValue = useAuth();
    const { t } = useTranslation("header");
    return (
        <Paper
            className="header"
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "30px",
                }}
            >
                <Link to="/">Home</Link>
                <Link to="/theme-testing">ThemeTesting</Link>
                <Link to="/rewind">Rewind</Link>
                <Link to="/task">Task</Link>
            </Box>

            <Box
                sx={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                {authContextValue?.currentUser ? (
                    <>
                        <Typography>
                            {t("welcomeText", "Hello")},&nbsp;
                            {authContextValue.currentUser.email}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={authContextValue.logOut}
                        >
                            Logout
                        </Button>
                    </>
                ) : (
                    <>
                        <Link to="/Login">Login</Link>
                    </>
                )}
            </Box>
        </Paper>
    );
};
