import TaskAPI, { Task } from "api/Task";
import {
  TaskNodeActionsContainer,
  TaskNodeChildrenContainer,
  TaskNodeContainer,
  TaskNodeContentContainer,
  TaskNodeDescriptionContainer,
} from "./TaskNode.style";
import { ChangeEvent, useEffect, useState } from "react";
import React from "react";
import { Icon, SvgName } from "components/Icon";
import { Box, Collapse, IconButton, Input } from "@mui/material";
import { TaskNodeStateType, TreeNode, TreeTaskNode } from "types/task/TaskNode";
import { TreeTask } from "utils/treeTask";
import { useTaskTreeService } from "services/TreeTaskService";
import {
  DISABLE_DEBUG_TASK_NODE,
  Phrase,
  PlaceType,
  getPlaceLogFunc,
} from "utils/debugLog";

interface PropType {
  nodeId: string;
}

const DEFAULT_NODE_STATE = TreeTask.DEFAULT_NODE_STATE;

function TaskNode({ nodeId }: PropType) {
  const logger = getPlaceLogFunc(
    PlaceType.Component,
    "TaskNode",
    DISABLE_DEBUG_TASK_NODE,
  );

  const { updateNode, addNode, treeNodes } = useTaskTreeService();

  const node = treeNodes.find((treeNode) => treeNode.id === nodeId);

  const nodeState: TaskNodeStateType = node?.state ?? DEFAULT_NODE_STATE;

  const initChildren = (
    children: TreeNode<Task, TaskNodeStateType>[] | undefined,
  ) => {
    if (!children) {
      return [];
    }
    return children
      .sort((a, b) => {
        const dateA = new Date(a.data.createdAt).getTime();
        const dateB = new Date(b.data.createdAt).getTime();
        return dateA - dateB;
      })
      .reverse();
  };

  const children = initChildren(node?.children);

  const isExpand = node ? node.state.isExpand : nodeState.isExpand;

  const [isAdding, setIsAdding] = useState(false);
  const [addingTaskText, setAddingTaskText] = useState("");

  const fetChildren = async () => {
    if (!node) {
      throw new Error("Node not found in the tree");
    }

    const data = await TaskAPI.getChildren(nodeId);

    const children = data.map((task) => ({
      ...task,
      children: [] as TreeTaskNode[],
      data: task,
      state: DEFAULT_NODE_STATE,
    }));

    logger(Phrase.EventTrigger, { children });

    // node.children = children;

    const updateNodeResult = await updateNode({
      ...node,
      children,
    });

    logger(Phrase.EventTrigger, { updateNodeResult }, "updateNodeResult");
    logger(Phrase.EventTrigger, { treeNodes }, "after updateNodeResult");
  };

  useEffect(() => {
    if (isExpand) {
      fetChildren();
    }
  }, [isExpand]);

  useEffect(() => {
    logger(Phrase.UseEffect, { treeNodes }, "treeNodes");
  }, [treeNodes]);

  async function handleToggleExpand(e: React.MouseEvent): Promise<void> {
    e.preventDefault();
    if (!node) {
      throw new Error("Node not found in the tree");
    }
    const nextExpandState = !isExpand;
    if (!nextExpandState && isAdding) {
      setIsAdding(false);
    }
    await toggleNode(node);
  }

  async function handleToggleAddChildren(e: React.MouseEvent) {
    e.preventDefault();
    if (!node) {
      throw new Error("Node not found in the tree");
    }
    logger(
      Phrase.EventTrigger,
      {
        node,
        isAdding,
      },
      "node",
    );

    if (!isAdding) {
      await toggleNode(node, true);
      setIsAdding(true);
      return;
    }

    await toggleNode(node, false);
    setIsAdding(false);
  }

  async function toggleNode(
    node: TreeTaskNode,
    forceIsExpandValue?: boolean,
  ): Promise<void> {
    logger(Phrase.EventTrigger, { node }, "push update");
    await updateNode({
      ...node,
      children,
      state: {
        ...nodeState,
        isExpand: forceIsExpandValue ?? !isExpand,
      },
    });
  }

  async function handleAddingTaskTextChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setAddingTaskText(event.target.value);
  }

  async function handleAddNewNode(e: React.MouseEvent): Promise<void> {
    e.preventDefault();
    if (!node) {
      throw new Error(`not found node id = ${nodeId} in tree`);
    }
    if (addingTaskText.trim() === "") {
      return;
    }

    // toggleNode()

    await addNode({
      content: addingTaskText,
      parentId: node.id,
    });
    // display the blank input -> auto focus -> enter to add
    // after hide the blank input
    setIsAdding(false);
    setAddingTaskText("");
  }

  logger(Phrase.Render, {
    nodeId,
    content: node?.data.content,
    isExpand,
    children,
    node,
    treeNodes,
  });

  if (!node) {
    window.console.error("Why not found ???");
    return <Box>node not found</Box>;
  }

  return (
    <TaskNodeContainer>
      <TaskNodeContentContainer>
        <TaskNodeDescriptionContainer>
          {node.data.content}
        </TaskNodeDescriptionContainer>
        <TaskNodeActionsContainer>
          <IconButton sx={{ padding: "2px" }} onClick={handleToggleAddChildren}>
            <Icon
              name={SvgName.AddCircle}
              sx={{
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
          <IconButton sx={{ padding: "2px" }} onClick={handleToggleExpand}>
            <Icon
              name={SvgName.ArrowRight}
              sx={{
                width: "20px",
                height: "20px",
                transform: isExpand ? "rotate(90deg)" : "",
                transition: "0.2s",
              }}
            />
          </IconButton>
        </TaskNodeActionsContainer>
      </TaskNodeContentContainer>
      {/* {isExpand ? "expand" : "not expand"}
      <br />1111
      {`cnt child: ${children?.length}`} */}
      <TaskNodeChildrenContainer>
        {isAdding && (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Input
              fullWidth
              // size="small"
              value={addingTaskText}
              placeholder="add new task"
              onChange={handleAddingTaskTextChange}
            />
            <IconButton onClick={handleAddNewNode}>
              <Icon
                name={SvgName.TickSquare}
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </IconButton>
          </Box>
        )}

        <Collapse in={isExpand} orientation="vertical">
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {children.map((child) => {
              return <TaskNode key={child.id} nodeId={child.id} />;
            })}
            {!children.length && <>blank</>}
          </Box>
        </Collapse>
      </TaskNodeChildrenContainer>
    </TaskNodeContainer>
  );
}

export default TaskNode;

// behaviors:
/*
  add node

  when collapse children
    if adding -> close adding too

*/
