import { useTranslation as useReactTranslation } from 'react-i18next';
const useTranslation = (prefix?: string) => {
    const { t: trans, i18n } = useReactTranslation()

    function t(key: string, defaultValue?: string) {
        const keyWithPrefix = `${prefix}.${key}`;
        return trans(keyWithPrefix, { defaultValue });
    }
    return { t, i18n }
}
export default useTranslation;