import { Task as TaskAPI, TaskNodeState } from "api/Task";
import { TaskNodeStateType, TreeTaskNode } from "types/task/TaskNode";
import {
  DISABLE_DEBUG_TASK_TREE_UTILS,
  Phrase,
  PlaceType,
  getPlaceLogFunc,
} from "./debugLog";

export class TreeTaskUtils {
  // static buildTaskTree(task: TaskAPI, state: TaskNodeState): TreeTaskNode {
  //   // load all node in state
  //   const root = task;
  //   // build a tree which node is root and
  //   return {
  //     id: -1,
  //     parentId: -1,
  //     children: [],
  //     data: null,
  //   };
  // }
}

const logger = getPlaceLogFunc(
  PlaceType.Component,
  "TaskTree",
  DISABLE_DEBUG_TASK_TREE_UTILS,
);

export class TreeTask {
  constructor(
    public rootId: string,
    public nodes: TaskAPI[],
  ) {
    logger(Phrase.Constructor, {
      rootId,
      nodes,
    });
    this.build();
  }
  public treeNodes: TreeTaskNode[] | null = null;
  public rootNode: TreeTaskNode | null = null;
  // change to private later
  public mapIdToNodeObj: Map<string, TreeTaskNode> | null = null;

  public static DEFAULT_NODE_STATE: TaskNodeStateType = {
    isExpand: false,
  };

  build() {
    this.mapIdToNodeObj = new Map<string, TreeTaskNode>();
    if (this.mapIdToNodeObj === null) {
      return;
    }
    this.treeNodes = [];

    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      const treeNode = {
        ...node,
        children: [],
        data: node,
        state: TreeTask.DEFAULT_NODE_STATE,
      };
      this.treeNodes?.push(treeNode);
      this.mapIdToNodeObj.set(node.id, treeNode);
    }

    logger(
      Phrase.Function,
      {
        nodes: this.nodes,
        treeNodes: this.treeNodes,
        mapIdToNodeObj: this.mapIdToNodeObj,
      },
      "this.",
    );

    const rootNode = this.mapIdToNodeObj.get(this.rootId);
    if (rootNode) {
      this.rootNode = rootNode;
    } else {
      throw new Error("Couldn't find root Node");
    }

    logger(
      Phrase.Function,
      {
        treeNodes: this.treeNodes,
      },
      "this.",
    );
  }

  applyState(state: TaskNodeState) {
    logger(
      Phrase.Function,
      {
        treeNodes: this.treeNodes,
        state,
      },
      "this.",
    );
  }

  addNode(node: TreeTaskNode) {
    if (this.mapIdToNodeObj?.has(node.id)) {
      throw new Error(`The tree already has the node with id ${node.id}`);
    }
    if (node.parentId === null) {
      throw new Error("Could add more root to a tree");
    }

    const parentNode = this.treeNodes?.find(
      (treeNode) => treeNode.id === node.parentId,
    );
    if (!parentNode) {
      throw new Error("Couldn't find a parent node of adding node in the tree");
    }
    logger(
      Phrase.Function,
      {
        node,
      },
      "treeDS: add node",
    );

    // TODO: this.mapIdToNodeObj this node have the same ref as this.treeNodes
    // do we have to update this.mapIdToNodeObj all the time
    this.mapIdToNodeObj?.set(node.id, node);

    this.nodes.push(node.data);
    this.treeNodes?.push(node);
    parentNode.children.push(node);

    logger(
      Phrase.Function,
      {
        node,
      },
      "treeDS: add node parentNode",
    );
  }

  updateNode(node: TreeTaskNode) {
    const treeNode = this.treeNodes?.find(
      (treeNode) => treeNode.id === node.id,
    );
    const treeNodeIndex = this.treeNodes?.findIndex(
      (treeNode) => treeNode.id === node.id,
    );

    logger(
      Phrase.Function,
      {
        node,
        treeNode,
        treeNodeIndex,
        treeNodes: this.treeNodes,
      },
      "treeDS: update node",
    );

    logger(Phrase.Function, { treeNodeIndex }, "updateNode");

    if (!this.treeNodes || !treeNode || treeNodeIndex === undefined) {
      throw new Error(`Couldn't find the node with id ${node.id}`);
    }

    logger(
      Phrase.Function,
      {
        node,
      },
      "treeDS: update node",
    );

    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];

      logger(
        Phrase.Function,
        {
          child,
          checkResult: this.mapIdToNodeObj?.has(child.id),
        },
        "check child exist",
      );

      if (!this.mapIdToNodeObj?.has(child.id)) {
        this.addNode(child);
        logger(
          Phrase.Function,
          {
            child,
            treeNodes: this.treeNodes,
          },
          "after add child",
        );
      }
    }

    this.treeNodes[treeNodeIndex] = {
      ...treeNode,
      ...node,
    };

    logger(
      Phrase.Function,
      {
        node: this.treeNodes[treeNodeIndex],
      },
      "treeDS: update node in treeNodes",
    );

    return treeNode;
  }
  // add node
  // update node
  // remove node
  // collapse a node
  // re-parent / move
}

// const tree = new TreeTask(1, []);
// currently we let init status gonna be the default state, expand = false
