import axios from "axios";

export const api = axios.create({
  baseURL: import.meta.env.VITE_BACK_END_URL,
  timeout: 5000,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log("sjet");
    }
    throw error;
  },
);
