import { Paper, Typography, styled } from "@mui/material";

export const PagePager = styled(Paper)(() => ({
  // border: "2px solid red",
  padding: "20px 40px ",
}));
export const PageHeader = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  borderBottom: `2px solid ${theme.palette.grey[300]}`,
  marginBottom: "10px",
}));
