import { RouteObject } from "react-router-dom";
// import { ScheduleDialogServiceProvider } from "services/ScheduleDialogService";
// import { withProviders } from "hoc/WithProviders";
import { lazyLoad } from "../LazyLoad";
import { PATH } from "../routeConfig";
// const providers = [ScheduleDialogServiceProvider];
export const themeTestingRoutes: RouteObject[] = [
    {
        path: PATH.THEME_TESTING,
        element: lazyLoad(() => import("pages/ThemeTesting/ThemeTesting")),
    },
];
