import { RouteObject } from "react-router-dom";
// import { ScheduleDialogServiceProvider } from "services/ScheduleDialogService";
// import { withProviders } from "hoc/WithProviders";
import { lazyLoad } from "../LazyLoad";
import { PATH } from "../routeConfig";
import { Route } from "../path";
import TaskTree from "components/TaskTree/TaskTree/TaskTree";
// const providers = [ScheduleDialogServiceProvider];
export const taskRoutes: RouteObject[] = [
  {
    path: PATH.TASK,
    element: lazyLoad(() => import("pages/Task/Task")),
    index: true,
  },
  {
    path: `${PATH.TASK}/${Route.id}`,
    element: <TaskTree />,
  },
];
