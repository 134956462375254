export enum Route {
  all = "*",
  id = ":id",
  code = ":code",
  root = "/",
  notFound = "/404",
  login = "login",
  register = "register",
  datacentral = "datacentral",
  customer = "customer",
  audition = "audition",
  electroniccommerce = "electroniccommerce",
  authority = "authority",
  report = "report",
  teachersmanagement = "teachersmanagement",
  teachersinterviewmanagement = "teachersinterviewmanagement",
  teacherunverifiedmanagement = "teacherunverifiedmanagement",
  financial = "financial",
  badgecentral = "badgecentral",
  tutorialcenter = "tutorialcenter",
  serverError = "500",
  permissionAbnormal = "permissionabnormal",
}

export const RouteName = {
  all: "全部",
  root: "oneClub",
  notFound: "找不到",
  login: "登入",
  register: "註冊",
  datacentral: "數據中心",
  customer: "會員管理",
  audition: "課務管理",
  electroniccommerce: "電子商務",
  authority: "權限管理",
  report: "統計報表",
  teachersmanagement: "教務管理",
  teachersinterviewmanagement: "教師面試",
  teacherunverifiedmanagement: "未驗證教師列表",
  financial: "財務管理",
  serverError: "伺服器錯誤",
  badgecentral: "出貨管理",
  tutorialcenter: "補教課程管理",
};

export enum FirstRoute {
  customerlist = "customerlist",
  visitorlist = "visitorlist",
  customer = "customer",
  period = "period",
  reservation = "reservation",
  vipPass = "vipPass",
  course = "course",
  coursetag = "coursetag",
  order = "order",
  orderlist = "orderlist",
  orderdetails = "orderdetails",
  commodity = "commodity",
  setting = "setting",
  employee = "employee",
  role = "role",
  department = "department",
  daily = "daily",
  realtime = "realtime",
  realtimecourse = "realtimecourse",
  performance = "performance",
  teacherlist = "teacherlist",
  teacherinterviewlist = "teacherinterviewlist",
  teacherunverifiedlist = "teacherunverifiedlist",
  teachermaterial = "teachermaterial",
  teacherApplication = "teacherApplication",
  teacherApplicationUnverified = "teacherApplicationUnverified",
  publicCourse = "publicCourse",
  trialcourse = "trialcourse",
  salary = "salary",
  salaryoverview = "salaryoverview",
  unearnedrevenue = "unearnedrevenue",
  laborcost = "laborcost",
  refundAssistance = "refundAssistance",
  rewardshipmentmanagement = "rewardshipmentmanagement",
  salesdata = "salesdata",
  financialdata = "financialdata",
  learningbar = "learningbar",
  textbookmangement = "textbookmangement",
}

export const FirstRouteName = {
  customerlist: "會員列表",
  visitorlist: "聯絡人列表",
  period: "試聽時段管理",
  reservation: "試聽預約",
  vipPass: "快速通道",
  course: "一般課程管理",
  trialcourse: "試聽課程管理",
  coursetag: "課程標籤",
  order: "訂單",
  orderlist: "訂單列表",
  orderdetails: "訂單詳情",
  commodity: "商品",
  setting: "設定",
  employee: "人員管理",
  role: "角色設定",
  department: "部門設定",
  daily: "業務日報",
  realtime: "即時業績",
  realtimecourse: "即時觀課管理",
  performance: "業績瀏覽",
  teacherlist: "教師列表",
  teacherinterviewlist: "教師面試",
  teacherunverifiedlist: "未驗證教師列表",
  teachermaterial: "教材資源",
  teacherApplication: "教師面試",
  teacherApplicationUnverified: "未驗證教師列表",
  publicCourse: "課外學習管理",
  salary: "教師薪資",
  salaryoverview: "薪資總覽",
  laborcost: "勞務成本表",
  refundAssistance: "退款協助",
  rewardshipmentmanagement: "獎品出貨管理",
  unearnedrevenue: "預收收入表",
  salesdata: "銷售數據",
  financialdata: "財務數據",
  learningbar: "學霸訂單",
  textbookmangement: "講義出貨管理",
};

export enum SecondRoute {
  spu = "spu",
  sku = "sku",
  plan = "plan",
  create = "create",
  read = "read",
  edit = "edit",
  copy = "copy",
  promotion = "promotion",
  payment = "payment",
  contract = "contract",
  mail = "mail",
  schedule = "schedule",
  taglist = "taglist",
  salarydetail = "salarydetail",
  unearnedrevenuedetail = "unearnedrevenuedetail",
  feedback = "feedback",
  customerdetail = ":customerId",
  seriesread = "seriesread",
  seriescreate = "seriescreate",
  seriesedit = "seriesedit",
  applicantView = "applicantView",
  resourcecreate = "resourcecreate",
  resourceedit = "resourceedit",
  visitorprocessed = "visitorprocessed",
  performancedetail = ":performanceId",
  rewardmerchandise = "rewardmerchandise",
  textbookdetail = ":textbookId",
}

export const SecondRouteName = {
  spu: "商品",
  sku: "sku",
  plan: "方案",
  create: "新增",
  read: "查看",
  edit: "編輯",
  copy: "新增",
  promotion: "促銷",
  payment: "金流",
  contract: "合約",
  mail: "通知信",
  order: "訂單",
  schedule: "行程",
  taglist: "教學風格標籤",
  salarydetail: "薪資明細",
  feedback: "課後回饋",
  customerdetail: "會員詳情",
  seriesread: "查看叢書",
  seriescreate: "新增叢書",
  seriesedit: "編輯叢書",
  resourcecreate: "新增叢書教材",
  resourceedit: "編輯叢書教材",
  visitorprocessed: "待處理區",
  performancedetail: "業績詳情",
  rewardmerchandise: "編輯兌換紀錄",
  unearnedrevenuedetail: "預收收入明細",
  textbookdetail: "講義出貨詳情",
};

export enum ThirdRoute {
  attribute = "attribute",
  create = "create",
  edit = "edit",
  studentcreate = "studentcreate",
  studentread = "studentread",
  studentedit = "studentedit",
  customseriescreate = "customseriescreate",
  customseriesedit = "customseriesedit",
}

export const ThirdRouteName = {
  attribute: "屬性管理",
  create: "新增",
  edit: "編輯",
  studentcreate: "新增成員",
  studentread: "查看成員",
  studentedit: "編輯成員",
  customseriescreate: "新增客製叢書",
  customseriesedit: "編輯客製叢書",
};
const path = (path?: Array<string>) => {
  return path ? `${Route.root}${path.join("/")}` : Route.root;
};

export default {
  ROOT: path(),
  SERVER_ERROR: path([Route.serverError]),
  NOT_FOUND: path([Route.notFound]),
  PERMISSION_ABNORMAL: path([Route.permissionAbnormal]),
  LOGIN: path([Route.login]),
  REGISTER: path([Route.register]),
  DATA_CENTRAL: path([Route.datacentral]),
  DATA_CENTER_SALES_DATA: path([Route.datacentral, FirstRoute.salesdata]),
  DATA_CENTER_FINANCIAL_DATA: path([
    Route.datacentral,
    FirstRoute.financialdata,
  ]),
  CUSTOMER: path([Route.customer]),
  CUSTOMER_LIST: path([Route.customer, FirstRoute.customerlist]),
  VISITOR_LIST: path([Route.customer, FirstRoute.visitorlist]),
  CUSTOMER_CREATE: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.create,
  ]),
  CUSTOMER_DETAIL: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
  ]),
  CUSTOMER_EDIT: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.edit,
  ]),
  CUSTOMER_STUDENT_CREATE: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.studentcreate,
  ]),
  CUSTOMER_STUDENT_READ: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.studentread,
  ]),
  CUSTOMER_STUDENT_EDIT: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.studentedit,
  ]),
  CUSTOMER_CUSTOME_SERIES_CREATE: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.customseriescreate,
  ]),
  CUSTOMER_CUSTOME_SERIES_EDIT: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.customseriesedit,
  ]),
  CUSTOMER_VISITOR_LIST: path([Route.customer, FirstRoute.visitorlist]),
  CUSTOMER_VISITOR_LIST_VISITORPROCESSED: path([
    Route.customer,
    FirstRoute.visitorlist,
    SecondRoute.visitorprocessed,
  ]),
  AUDITION: path([Route.audition]),
  AUDITION_PERIOD: path([Route.audition, FirstRoute.period]),
  AUDITION_RESERVATION: path([Route.audition, FirstRoute.reservation]),
  AUDITION_VIP_PASS: path([Route.audition, FirstRoute.vipPass]),
  AUDITION_TRIAL_COURSE: path([Route.audition, FirstRoute.trialcourse]),
  AUDITION_TRIAL_COURSE_READ: path([
    Route.audition,
    FirstRoute.trialcourse,
    SecondRoute.read,
  ]),
  AUDITION_COURSE: path([Route.audition, FirstRoute.course]),
  AUDITION_COURSE_CREATE: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.create,
  ]),
  AUDITION_COURSE_COPY: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.copy,
  ]),
  AUDITION_COURSE_READ: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.read,
  ]),
  AUDITION_COURSE_EDIT: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.edit,
  ]),
  AUDITION_PUBLIC_COURSE: path([Route.audition, FirstRoute.publicCourse]),
  AUDITION_PUBLIC_COURSE_CREATE: path([
    Route.audition,
    FirstRoute.publicCourse,
    SecondRoute.create,
  ]),
  AUDITION_PUBLIC_COURSE_READ: path([
    Route.audition,
    FirstRoute.publicCourse,
    SecondRoute.read,
  ]),
  AUDITION_PUBLIC_COURSE_EDIT: path([
    Route.audition,
    FirstRoute.publicCourse,
    SecondRoute.edit,
  ]),
  AUDITION_REALTIME_COURSE: path([Route.audition, FirstRoute.realtimecourse]),
  AUDITION_REALTIME_COURSE_READ: path([
    Route.audition,
    FirstRoute.realtimecourse,
    SecondRoute.read,
  ]),
  AUDITION_COURSETAG: path([Route.audition, FirstRoute.coursetag]),
  AUDITION_COURSE_FEEDBACK: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.feedback,
  ]),
  ELECTRONICCOMMERCE: path([Route.electroniccommerce]),
  ELECTRONICCOMMERCE_COMMODITY: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU_ATTRIBUTES: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
    ThirdRoute.attribute,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SPU: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.spu,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SPU_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.spu,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SPU_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.spu,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PLAN: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.plan,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PLAN_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.plan,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PLAN_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.plan,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PROMOTION: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.promotion,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PROMOTION_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.promotion,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PROMOTION_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.promotion,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING: path([
    Route.electroniccommerce,
    FirstRoute.setting,
  ]),
  ELECTRONICCOMMERCE_SETTING_PAYMENT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.payment,
  ]),
  ELECTRONICCOMMERCE_SETTING_CONTRACT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.contract,
  ]),
  ELECTRONICCOMMERCE_SETTING_CONTRACT_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.contract,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING_MAIL: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.mail,
  ]),
  ELECTRONICCOMMERCE_ORDER: path([Route.electroniccommerce, FirstRoute.order]),
  ELECTRONICCOMMERCE_ORDER_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.create,
  ]),
  ELECTRONICCOMMERCE_ORDER_COPY: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.copy,
  ]),
  ELECTRONICCOMMERCE_ORDER_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.edit,
  ]),
  ELECTRONICCOMMERCE_REFUND_ASSISTANCE: path([
    Route.electroniccommerce,
    FirstRoute.refundAssistance,
  ]),
  TEACHER_MANAGEMENT: path([Route.teachersmanagement]),
  TEACHER_MANAGEMENT_LIST: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
  ]),

  TEACHER_MANAGEMENT_CREATE: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.create,
  ]),
  TEACHER_MANAGEMENT_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.edit,
  ]),
  TEACHER_MANAGEMENT_SCHEDULE: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.schedule,
  ]),
  TEACHER_MANAGEMENT_TAG: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.taglist,
  ]),
  TEACHER_MANAGEMENT_MATERIAL: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
  ]),
  TEACHER_INTERVIEW_MANAGEMENT_LIST: path([
    Route.teachersmanagement,
    FirstRoute.teacherinterviewlist,
  ]),
  TEACHER_UNVERIFIED_MANAGEMENT_LIST: path([
    Route.teachersmanagement,
    FirstRoute.teacherunverifiedlist,
  ]),
  TEACHER_INTERVIEW_MANAGEMENT_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teacherinterviewlist,
    SecondRoute.applicantView,
  ]),
  TEACHER_UNVERIFIED_MANAGEMENT_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teacherunverifiedlist,
    SecondRoute.applicantView,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_SERIES_READ: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.seriesread,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_SERIES_CREATE: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.seriescreate,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_SERIES_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.seriesedit,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_RESOURCE_CREATE: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.resourcecreate,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_RESOURCE_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.resourceedit,
  ]),
  REPORT: path([Route.report]),
  REPORT_DAILY: path([Route.report, FirstRoute.daily]),
  REPORT_REALTIME: path([Route.report, FirstRoute.realtime]),
  REPORT_PERFORMANCE: path([Route.report, FirstRoute.performance]),
  REPORT_PERFORMANCE_DETAIL: path([
    Route.report,
    FirstRoute.performance,
    SecondRoute.performancedetail,
  ]),
  REPORT_PERFORMANCE_DETAIL_EDIT: path([
    Route.report,
    FirstRoute.performance,
    SecondRoute.performancedetail,
    ThirdRoute.edit,
  ]),
  AUTHORITY: path([Route.authority]),
  AUTHORITY_EMPLOYEE: path([Route.authority, FirstRoute.employee]),
  AUTHORITY_ROLE: path([Route.authority, FirstRoute.role]),
  AUTHORITY_DEPARTMENT: path([Route.authority, FirstRoute.department]),
  FINANCIAL: path([Route.financial]),
  FINANCIAL_SALARY: path([Route.financial, FirstRoute.salary]),
  FINANCIAL_SALARY_OVERVIEW: path([Route.financial, FirstRoute.salaryoverview]),
  FINANCIAL_LABOR_COST_LIST: path([Route.financial, FirstRoute.laborcost]),
  FINANCIAL_SALARY_DETAIL: path([
    Route.financial,
    FirstRoute.salary,
    SecondRoute.salarydetail,
  ]),
  FINANCIAL_UNEARNED_REVENUE: path([
    Route.financial,
    FirstRoute.unearnedrevenue,
  ]),
  FINANCIAL_UNEARNED_REVENUE_DETAIL: path([
    Route.financial,
    FirstRoute.unearnedrevenue,
    SecondRoute.unearnedrevenuedetail,
  ]),
  TUTORIAL_CENTER: path([Route.tutorialcenter]),
  TUTORIAL_CENTER_FORMAL_COURSE: path([
    Route.tutorialcenter,
    FirstRoute.course,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_CREATE: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.create,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_READ: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.read,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_EDIT: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.edit,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_COPY: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.copy,
  ]),
  MEDAL_CENTRAL: path([Route.badgecentral]),
  MEDAL_CENTRAL_REWARD_REDEMPTION: path([
    Route.badgecentral,
    FirstRoute.rewardshipmentmanagement,
  ]),
  MEDAL_CENTRAL_REWARD_REDEMPTION_EDIT: path([
    Route.badgecentral,
    FirstRoute.rewardshipmentmanagement,
    SecondRoute.edit,
  ]),
  TEXTBOOK_MANAGEMENT: path([Route.badgecentral, FirstRoute.textbookmangement]),
  TEXTBOOK_CREATE: path([
    Route.badgecentral,
    FirstRoute.textbookmangement,
    SecondRoute.create,
  ]),
  TEXTBOOK_DETAIL: path([
    Route.badgecentral,
    FirstRoute.textbookmangement,
    SecondRoute.textbookdetail,
  ]),
  TEXTBOOK_EDIT: path([
    Route.badgecentral,
    FirstRoute.textbookmangement,
    SecondRoute.textbookdetail,
    ThirdRoute.edit,
  ]),
};

export enum PREFIX {
  DATACENTRAL = "data_central",
  CUSTOMER = "customer",
  AUDITION = "audition",
  ELECTRONICCOMMERCE = "electroniccommerce",
  REPORT = "report",
  AUTHORITY = "authority",
}

export enum Auth {
  // new
  DATA_CENTRAL_SALES_READ = "data_central_sales:read",
  DATA_CENTRAL_FINANCE_READ = "data_central_finance:read",
  VISITER_DATA_READ = "visiter_data:read",
  VISITER_DATA_IMPORT = "visiter_data:import",
  COURSE_FORMAL_READ = "course_formal:read",
  COURSE_FORMAL_DELETE = "course_formal:delete",
  COURSE_FORMAL_EDIT = "course_formal:edit",
  COURSE_FORMAL_URL_READ = "course_formal_url:read",
  COURSE_DATA_EXPORT = "course_data:export",
  COURSE_AUDITION_READ = "course_audition:read",
  COURSE_AUDITION_EDIT = "course_audition:edit",
  COURSE_AUDITION_DELETE = "course_audition:delete",
  COURSE_AUDITION_OWN_READ = "course_audition_own:read",
  COURSE_AUDITION_URL_READ = "course_audition_url:read",
  COURSE_CREATE = "course:create",
  AFTER_CLASS_STUDY_READ = "after_class_study:read",
  AFTER_CLASS_STUDY_CREATE = "after_class_study:create",
  AFTER_CLASS_STUDY_EDIT = "after_class_study:edit",
  AFTER_CLASS_STUDY_CANCEL = "after_class_study:cancel",
  AFTER_CLASS_STUDY_EXPORT = "after_class_study:export",
  COURSE_OBSERVATION_READ = "course_observation:read",
  COURSE_TAG_READ = "course_tag:read",
  COURSE_TAG_IMPORT = "course_tag:import",
  COURSE_TAG_DELETE = "course_tag:delete",
  TUTORIAL_CENTER_AUDITION_BOOK_READ = "tutorial_center_audition_book:read",
  TUTORIAL_CENTER_AUDITION_SCHEDULE_READ = "tutorial_center_audition_schedule:read",
  TUTORIAL_CENTER_AUDITION_SCHEDULE_CREATE = "tutorial_center_audition_schedule:create",
  TUTORIAL_CENTER_AUDITION_SCHEDULE_DELETE = "tutorial_center_audition_schedule:delete",
  TUTORIAL_CENTER_COURSE_FORMAL_READ = "tutorial_center_course_formal:read",
  TUTORIAL_CENTER_COURSE_AUDITION_READ = "tutorial_center_course_audition:read",
  TUTORIAL_CENTER_COURSE_AUDITION_OWN_READ = "tutorial_center_course_audition_own:read",
  TUTORIAL_CENTER_COURSE_CREATE = "tutorial_center_course:create",
  TUTORIAL_CENTER_COURSE_FORMAL_EDIT = "tutorial_center_course_formal:edit",
  TUTORIAL_CENTER_COURSE_AUDITION_EDIT = "tutorial_center_course_audition:edit",
  TUTORIAL_CENTER_COURSE_FORMAL_DELETE = "tutorial_center_course_formal:delete",
  TUTORIAL_CENTER_COURSE_AUDITION_DELETE = "tutorial_center_course_audition:delete",
  TUTORIAL_CENTER_COURSE_FORMAL_URL_READ = "tutorial_center_course_formal_url:read",
  TUTORIAL_CENTER_COURSE_AUDITION_URL_READ = "tutorial_center_course_audition_url:read",
  TUTORIAL_CENTER_COURSE_DATA_EXPORT = "tutorial_center_course_data:export",
  TEACHER_READ = "teacher:read",
  TEACHER_SCHEDULE_CREATE = "teacher_schedule:create",
  TEACHER_SCHEDULE_DELETE = "teacher_schedule:delete",
  TEACHER_SCHEDULE_LEAVE = "teacher_schedule:leave",
  TEACHER_UNVERIFIED_READ = "teacher_unverified:read",
  TEACHER_CREATE = "teacher:create",
  TEACHER_EDIT = "teacher:edit",
  TEACHER_APPLICATION_READ = "teacher_application:read",
  TEACHER_APPLICATION_READ_UNVERIFIED = "teacher_application:readUnverified",
  TEACHER_APPLICATION_EDIT = "teacher_application:edit",
  TEACHER_APPLICATION_REVIEW_INIT = "teacher_application:reviewInit",
  TEACHER_APPLICATION_REVIEW_SECOND = "teacher_application:reviewSecond",
  MATERIAL_READ = "material:read",
  MATERIAL_CREATE = "material:create",
  MATERIAL_EDIT = "material:edit",
  MATERIAL_IMPORT = "material:import",
  MATERIAL_EXAM = "material:exam",
  MATERIAL_DELETE = "material:delete",
  TEACHER_SALARY_READ = "teacher_salary:read",
  TEACHER_SALARY_EDIT = "teacher_salary:edit",
  TEACHER_SALARY_EXPORT = "teacher_salary:export",
  SALARY_OVERALL_READ = "salary_overall:read",
  SALARY_OVERALL_EDIT = "salary_overall:edit",
  SALARY_OVERALL_EXPORT = "salary_overall:export",
  LABOR_COST_READ = "labor_cost:view",
  LABOR_COST_EXPORT = "labor_cost:export",
  PERFORMANCE_ALL_READ = "performance_all:read",
  PERFORMANCE_OWN_READ = "performance_own:read",
  PERFORMANCE_EXPORT = "performance:export",
  PERFORMANCE_EDIT = "performance:edit",
  // old
  CUSTOMER_CUSTOMER = "customer_customer:read",
  CUSTOMER_CUSTOMER_EDIT = "customer_customer:edit",
  CUSTOMER_OWN_CUSTOMER_READ = "customer_own_customer:read",
  CUSTOMER_ALL_CUSTOMER_READ = "customer_all_customer:read",
  CUSTOMER_SCHEDULE_PREFERENCE_EXPORT = "customer_schedule_preference:export",
  CUSTOMER_DATA_CREATE = "customer_data:create",
  CUSTOMER_DATA_IMPORT = "customer_data:import",
  CUSTOMER_LEARNINGBAR_DATA_CREATE = "customer_learningbar_data:create",
  CUSTOMER_ORGANIZATION_GROUP_EDIT = "customer_organization_group:edit",
  CUSTOMER_TYPE_READ = "customer_type:read",
  CUSTOMER_TYPE_EDIT = "customer_type:edit",
  CUSTOMER_BXID_READ = "customer_bxid:read",
  CUSTOMER_BXID_EDIT = "customer_bxid:edit",
  CUSTOMER_ID_READ = "customer_id:read",
  CUSTOMER_CHILDREN_CREATE = "customer_children:create",
  CUSTOMER_CHILDREN_READ = "customer_children:read",
  CUSTOMER_CHILDREN_EDIT = "customer_children:edit",
  CUSTOMER_COURSE_CREATE = "customer_course:create",
  CUSTOMER_SALES_READ = "customer_sales:read",
  CUSTOMER_SALES_EDIT = "customer_sales:edit",
  CUSTOMER_CONSULTER_READ = "customer_consulter:read",
  CUSTOMER_CONSULTER_EDIT = "customer_consulter:edit",
  CUSTOMER_INFO_READ = "customer_info:read",
  CUSTOMER_INFO_EDIT = "customer_info:edit",
  CUSTOMER_NOTE_READ = "customer_note:read",
  CUSTOMER_NOTE_EDIT = "customer_note:edit",
  CUSTOMER_AVAILABLE_COURSE_READ = "customer_available_course:read",
  CUSTOMER_AVAILABLE_COURSE_RECORD_READ = "customer_available_course_record:read",
  CUSTOMER_AVAILABLE_COURSE_RECORD_EDIT = "customer_available_course_record:edit",
  CUSTOMER_CONTRACT_READ = "customer_contract:read",
  CUSTOMER_CONTRACT_RECORD_READ = "customer_contract_record:read",
  CUSTOMER_CONTRACT_RECORD_EDIT = "customer_contract_record:edit",
  CUSTOMER_BILL_READ = "customer_bill:read",
  CUSTOMER_BILL_EDIT = "electroniccommerce_order:transfer",
  CUSTOMER_COURSE_READ = "customer_course:read",
  CUSTOMER_COURSE_EDIT = "customer_customer:courseTransfer",
  AUDITION_PERIOD_READ = "audition_period:read",
  AUDITION_PERIOD_CREATE = "audition_period:create",
  AUDITION_PERIOD_EDIT = "audition_period:edit",
  AUDITION_PERIOD_DELETE = "audition_period:delete",
  AUDITION_RESERVATION_READ = "audition_reservation:read",
  AUDITION_RESERVATION_CREATE = "audition_reservation:create",
  POINT_MANAGE_READ = "point_manage:read",
  POINT_MANAGE_EDIT = "point_manage:edit",
  // ----- new Audition authorization -----
  AUDITION_BOOK_READ = "audition_book:read",
  AUDITION_SCHEDULE_READ = "audition_schedule:read",
  AUDITION_SCHEDULE_CREATE = "audition_schedule:create",
  AUDITION_SCHEDULE_DELETE = "audition_schedule:delete",
  // ----- new Audition authorization -----
  AUDITION_VIP_PASS_CREATE = "audition_vipPass:create",
  AUDITION_COURSE_READ = "audition_course:read",
  AUDITION_COURSE_CREATE = "audition_course:create",
  AUDITION_COURSE_EDIT = "audition_course:edit",
  AUDITION_COURSE_LINK = "audition_course:link",
  AUDITION_COURSE_CANCEL = "audition_course:cancel",
  AUDITION_PUBLIC_COURSE_READ = "audition_course:read",
  AUDITION_PUBLIC_COURSE_CREATE = "audition_course:create",
  AUDITION_PUBLIC_COURSE_EDIT = "audition_course:edit",
  AUDITION_PUBLIC_COURSE_LINK = "audition_course:link",
  AUDITION_PUBLIC_COURSE_CANCEL = "audition_course:cancel",
  AUDITION_COURSE_FEEDBACK = "audition_course_feedback",
  // AUDITION_TAG= 'audition_course:cancel',
  ELECTRONICCOMMERCE_COMMODITY_READ = "electroniccommerce_commodity:read",
  ELECTRONICCOMMERCE_COMMODITY_CREATE = "electroniccommerce_commodity:create",
  ELECTRONICCOMMERCE_COMMODITY_EDIT = "electroniccommerce_commodity:edit",
  ELECTRONICCOMMERCE_SETTING_READ = "electroniccommerce_setting:read",
  ELECTRONICCOMMERCE_SETTING_EDIT = "electroniccommerce_setting:edit",
  ELECTRONICCOMMERCE_ORDER_READ = "electroniccommerce_order:read",
  ELECTRONICCOMMERCE_ORDER_EXPORT = "electroniccommerce_order:export",
  ELECTRONICCOMMERCE_ORDER_CREATE = "electroniccommerce_order:create",
  ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE = "electroniccommerce_order:editBeforePurchase",
  ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE = "electroniccommerce_order:editAfterPurchase",
  ELECTRONICCOMMERCE_ORDER_CANCEL = "electroniccommerce_order:cancel",
  ELECTRONICCOMMERCE_ORDER_CLOSE = "electroniccommerce_order:close",
  ELECTRONICCOMMERCE_ORDER_EMAIL = "electroniccommerce_order:email",
  ELECTRONICCOMMERCE_ORDER_INVOICE = "electroniccommerce_order:invoice",
  ELECTRONICCOMMERCE_ORDER_FUND = "electroniccommerce_order:fund",
  ELECTRONICCOMMERCE_ORDER_REFUND = "electroniccommerce_order:refund",
  ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE = "electroniccommerce_learningbar_order:create",
  ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE = "electroniccommerce_learningbar_order:editBeforePurchase",
  ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE = "electroniccommerce_learningbar_order:editAfterPurchase",
  AUTHORITY_EMPLOYEE_READ = "authority_employee:read",
  AUTHORITY_EMPLOYEE_EDIT = "authority_employee:edit",
  AUTHORITY_ROLE_READ = "authority_role:read",
  AUTHORITY_ROLE_EDIT = "authority_role:edit",
  AUTHORITY_DEPARTMENT_READ = "authority_department:read",
  AUTHORITY_DEPARTMENT_EDIT = "authority_department:edit",
  REPORT_DAILY_READ = "report_daily:read",
  REPORT_REALTIME_READ = "report_realtime:read",
  BADGE_READ = "badge:read",
  BADGE_EDIT = "badge:edit",
  BADGE_EXPORT = "badge:export",
  BADGE_IMPORT = "badge:import",
  REVENUE_READ = "revenue:read",
  REVENUE_EXPORT = "revenue:export",
  TEXTBOOK_READ = "textbook:read",
  TEXTBOOK_READ_INFO = "textbook:read_info",
  TEXTBOOK_READ_MEMBER = "textbook:read_member",
  TEXTBOOK_READ_MEMBER_ALL = "textbook:read_member_all",
  TEXTBOOK_CREATE = "textbook:create",
  TEXTBOOK_EDIT = "textbook:edit",
  TEXTBOOK_EXPORT = "textbook:export",
  TEXTBOOK_IMPORT = "textbook:import",
}
export const AuthName = {
  [Auth.CUSTOMER_OWN_CUSTOMER_READ]: "該人員負責會員",
  [Auth.CUSTOMER_ALL_CUSTOMER_READ]: "所有會員",
  [Auth.CUSTOMER_SCHEDULE_PREFERENCE_EXPORT]: "匯出排課偏好",
  [Auth.CUSTOMER_DATA_CREATE]: "新增會員",
  [Auth.CUSTOMER_LEARNINGBAR_DATA_CREATE]: "新增會員",
  [Auth.CUSTOMER_ORGANIZATION_GROUP_EDIT]: "機構/班級",
  [Auth.CUSTOMER_TYPE_READ]: "會員類型",
  [Auth.CUSTOMER_TYPE_EDIT]: "會員類型",
  [Auth.CUSTOMER_DATA_IMPORT]: "檔次匯入",
  [Auth.CUSTOMER_BXID_READ]: "Bitrix ID",
  [Auth.CUSTOMER_BXID_EDIT]: "Bitrix ID",
  [Auth.CUSTOMER_ID_READ]: "OneClub ID",
  [Auth.CUSTOMER_CHILDREN_CREATE]: "新增成員",
  [Auth.CUSTOMER_CHILDREN_READ]: "成員",
  [Auth.CUSTOMER_CHILDREN_EDIT]: "編輯成員",
  [Auth.CUSTOMER_COURSE_CREATE]: "新增課程",
  [Auth.CUSTOMER_SALES_READ]: "顧問",
  [Auth.CUSTOMER_SALES_EDIT]: "編輯顧問",
  [Auth.CUSTOMER_CONSULTER_READ]: "輔導老師",
  [Auth.CUSTOMER_CONSULTER_EDIT]: "編輯輔導老師",
  [Auth.CUSTOMER_INFO_READ]: "基本資料",
  [Auth.CUSTOMER_INFO_EDIT]: "編輯基本資料",
  [Auth.CUSTOMER_NOTE_READ]: "備註資料",
  [Auth.CUSTOMER_NOTE_EDIT]: "編輯備註資料",
  [Auth.CUSTOMER_AVAILABLE_COURSE_READ]: "可用堂數資料",
  [Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_READ]: "堂數異動紀錄",
  [Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_EDIT]: "編輯堂數異動紀錄",
  [Auth.CUSTOMER_CONTRACT_READ]: "合約到期日",
  [Auth.CUSTOMER_CONTRACT_RECORD_READ]: "合約到期日異動紀錄",
  [Auth.CUSTOMER_CONTRACT_RECORD_EDIT]: "編輯合約到期日異動紀錄",
  [Auth.CUSTOMER_BILL_READ]: "訂單紀錄",
  [Auth.CUSTOMER_BILL_EDIT]: "轉移訂單",
  [Auth.CUSTOMER_COURSE_READ]: "課程紀錄",
  [Auth.CUSTOMER_COURSE_EDIT]: "轉移課程",
  [Auth.AUDITION_BOOK_READ]: "可預約時段",
  [Auth.AUDITION_SCHEDULE_READ]: "已預約時段",
  [Auth.AUDITION_SCHEDULE_CREATE]: "新增試聽",
  [Auth.AUDITION_SCHEDULE_DELETE]: "取消試聽",
  [Auth.AUDITION_COURSE_READ]: "所有試聽課程",
  // NEW
  [Auth.DATA_CENTRAL_SALES_READ]: "銷售數據",
  [Auth.DATA_CENTRAL_FINANCE_READ]: "財務數據",
  [Auth.VISITER_DATA_READ]: "聯絡人資料",
  [Auth.VISITER_DATA_IMPORT]: "匯入檔次",
  [Auth.AUDITION_VIP_PASS_CREATE]: "快速通道",
  [Auth.COURSE_FORMAL_READ]: "課程詳情",
  [Auth.COURSE_AUDITION_READ]: "所有課程詳情",
  [Auth.COURSE_AUDITION_OWN_READ]: "該人員負責課程",
  [Auth.COURSE_CREATE]: "新增課程",
  [Auth.COURSE_FORMAL_EDIT]: "編輯課程",
  [Auth.COURSE_AUDITION_EDIT]: "編輯課程",
  [Auth.COURSE_FORMAL_DELETE]: "取消課程",
  [Auth.COURSE_AUDITION_DELETE]: "取消課程",
  [Auth.COURSE_FORMAL_URL_READ]: "上課連結",
  [Auth.COURSE_AUDITION_URL_READ]: "上課連結",
  [Auth.COURSE_DATA_EXPORT]: "匯出課程紀錄",
  [Auth.AFTER_CLASS_STUDY_READ]: "課程詳情",
  [Auth.AFTER_CLASS_STUDY_CREATE]: "新增課程",
  [Auth.AFTER_CLASS_STUDY_EDIT]: "編輯課程",
  [Auth.AFTER_CLASS_STUDY_CANCEL]: "取消課程",
  [Auth.AFTER_CLASS_STUDY_EXPORT]: "匯出",
  [Auth.COURSE_OBSERVATION_READ]: "課程詳情",
  [Auth.COURSE_TAG_READ]: "課程標籤",
  [Auth.COURSE_TAG_IMPORT]: "匯入",
  [Auth.COURSE_TAG_DELETE]: "刪除",
  [Auth.TUTORIAL_CENTER_AUDITION_BOOK_READ]: "可預約時段",
  [Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_READ]: "已預約時段",
  [Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_CREATE]: "新增試聽",
  [Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_DELETE]: "取消試聽",
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_READ]: "查看正式課",
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_READ]: "查看所有試聽課",
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_OWN_READ]: "查看該人員所有試聽課",
  [Auth.TUTORIAL_CENTER_COURSE_CREATE]: "新增課程",
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_EDIT]: "編輯正式課",
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_EDIT]: "編輯試聽課",
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_DELETE]: "取消課程",
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_DELETE]: "取消課程",
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_URL_READ]: "上課連結",
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_URL_READ]: "上課連結",
  [Auth.TUTORIAL_CENTER_COURSE_DATA_EXPORT]: "匯出課程紀錄",
  [Auth.TEACHER_READ]: "教師列表",
  [Auth.TEACHER_CREATE]: "新增教師",
  [Auth.TEACHER_EDIT]: "編輯教師",
  [Auth.TEACHER_SCHEDULE_CREATE]: "新增排班",
  [Auth.TEACHER_SCHEDULE_DELETE]: "清除排班",
  [Auth.TEACHER_SCHEDULE_LEAVE]: "請假",
  [Auth.MATERIAL_READ]: "教材資源",
  [Auth.TEACHER_APPLICATION_EDIT]: "編輯教師",
  [Auth.TEACHER_APPLICATION_READ]: "教師面試",
  [Auth.TEACHER_APPLICATION_READ_UNVERIFIED]: "未驗證教師列表",
  [Auth.MATERIAL_CREATE]: "新增",
  [Auth.MATERIAL_EDIT]: "編輯",
  [Auth.MATERIAL_IMPORT]: "匯入",
  [Auth.MATERIAL_EXAM]: "派測驗",
  [Auth.MATERIAL_DELETE]: "刪除",
  [Auth.TEACHER_SALARY_READ]: "老師薪資",
  [Auth.TEACHER_SALARY_EDIT]: "編輯",
  [Auth.TEACHER_SALARY_EXPORT]: "匯出報表",
  [Auth.SALARY_OVERALL_READ]: "薪資總覽",
  [Auth.SALARY_OVERALL_EDIT]: "編輯",
  [Auth.SALARY_OVERALL_EXPORT]: "匯出報表",
  [Auth.LABOR_COST_READ]: "勞務成本表",
  [Auth.LABOR_COST_EXPORT]: "匯出報表",
  [Auth.PERFORMANCE_ALL_READ]: "所有業績",
  [Auth.PERFORMANCE_OWN_READ]: "該人員負責的業績",
  [Auth.PERFORMANCE_EXPORT]: "匯出報表",
  [Auth.PERFORMANCE_EDIT]: "業績詳情",
  [Auth.BADGE_READ]: "出貨查看",
  [Auth.BADGE_EDIT]: "出貨編輯",
  [Auth.REVENUE_READ]: "預收收入表",
  [Auth.REVENUE_EXPORT]: "匯出報表",
  [Auth.ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE]: "編輯未付款訂單",
  [Auth.ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE]: "編輯已付款訂單",
  [Auth.ELECTRONICCOMMERCE_ORDER_READ]: "查看",
  [Auth.ELECTRONICCOMMERCE_ORDER_EXPORT]: "匯出",
  [Auth.ELECTRONICCOMMERCE_ORDER_CREATE]: "新增訂單",
  [Auth.ELECTRONICCOMMERCE_ORDER_CANCEL]: "取消未付款訂單",
  [Auth.ELECTRONICCOMMERCE_ORDER_CLOSE]: "結案訂單",
  [Auth.ELECTRONICCOMMERCE_ORDER_EMAIL]: "寄送通知信",
  [Auth.ELECTRONICCOMMERCE_ORDER_INVOICE]: "登錄發票",
  [Auth.ELECTRONICCOMMERCE_ORDER_FUND]: "請款",
  [Auth.ELECTRONICCOMMERCE_ORDER_REFUND]: "退款",
  [Auth.ELECTRONICCOMMERCE_SETTING_READ]: "查看設定",
  [Auth.ELECTRONICCOMMERCE_SETTING_EDIT]: "編輯設定",
  [Auth.ELECTRONICCOMMERCE_COMMODITY_READ]: "查看商品",
  [Auth.ELECTRONICCOMMERCE_COMMODITY_EDIT]: "編輯商品",
  [Auth.ELECTRONICCOMMERCE_COMMODITY_CREATE]: "新增商品",
  [Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE]: "新增訂單",
  [Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE]:
    "編輯未付款訂單",
  [Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE]:
    "編輯已付款訂單",
  [Auth.TEXTBOOK_READ]: "列表",
  [Auth.TEXTBOOK_READ_INFO]: "詳情",
  [Auth.TEXTBOOK_READ_MEMBER]: "所有會員",
  [Auth.TEXTBOOK_READ_MEMBER_ALL]: "負責的會員",
  [Auth.TEXTBOOK_CREATE]: "新增",
  [Auth.TEXTBOOK_EDIT]: "編輯",
  [Auth.TEXTBOOK_EXPORT]: "匯出",
  [Auth.TEXTBOOK_IMPORT]: "匯入",
} as const;

// 舊權限清單
export const OLD_AUTH_LIST = [
  Auth.POINT_MANAGE_READ,
  Auth.POINT_MANAGE_EDIT,
  Auth.AUDITION_PERIOD_READ,
  Auth.AUDITION_PERIOD_CREATE,
  Auth.AUDITION_PERIOD_EDIT,
  Auth.AUDITION_PERIOD_DELETE,
  Auth.AUDITION_RESERVATION_READ,
  Auth.AUDITION_RESERVATION_CREATE,
  Auth.CUSTOMER_CUSTOMER,
];

// #region 會員管理權限
export const dataCentralRead = [
  Auth.DATA_CENTRAL_SALES_READ,
  Auth.DATA_CENTRAL_FINANCE_READ,
];
export const dataCentralSalesRead = [Auth.DATA_CENTRAL_SALES_READ];
export const dataCentralFinanceRead = [Auth.DATA_CENTRAL_FINANCE_READ];
export const visiterDataRead = [Auth.VISITER_DATA_READ];
export const visiterDataEdit = [Auth.VISITER_DATA_IMPORT];
export const customerDataRead = [
  Auth.CUSTOMER_ALL_CUSTOMER_READ,
  Auth.CUSTOMER_OWN_CUSTOMER_READ,
  Auth.CUSTOMER_SCHEDULE_PREFERENCE_EXPORT,
];
export const customerCreate = [
  Auth.CUSTOMER_DATA_CREATE,
  Auth.CUSTOMER_LEARNINGBAR_DATA_CREATE,
];
export const customerLearningbarCreate = [
  Auth.CUSTOMER_LEARNINGBAR_DATA_CREATE,
];
export const customerOrganizationGroupEdit = [
  Auth.CUSTOMER_ORGANIZATION_GROUP_EDIT,
];
export const customerTypeRead = [Auth.CUSTOMER_TYPE_READ];
export const customerTypeEdit = [Auth.CUSTOMER_TYPE_EDIT];
export const customerDataEdit = [
  Auth.CUSTOMER_DATA_CREATE,
  Auth.CUSTOMER_DATA_IMPORT,
];
export const customerLearningbarEdit = [...customerLearningbarCreate];
export const customerAccountRead = [
  Auth.CUSTOMER_BXID_READ,
  Auth.CUSTOMER_ID_READ,
  Auth.CUSTOMER_SALES_READ,
  Auth.CUSTOMER_CONSULTER_READ,
  Auth.CUSTOMER_TYPE_READ,
];
export const customerAccountEdit = [
  Auth.CUSTOMER_CHILDREN_CREATE,
  Auth.CUSTOMER_CHILDREN_EDIT,
  Auth.CUSTOMER_COURSE_CREATE,
  Auth.CUSTOMER_SALES_EDIT,
  Auth.CUSTOMER_CONSULTER_EDIT,
  Auth.CUSTOMER_BXID_EDIT,
  Auth.CUSTOMER_TYPE_EDIT,
  Auth.CUSTOMER_ORGANIZATION_GROUP_EDIT,
];
export const customerInfoRead = [Auth.CUSTOMER_INFO_READ];
export const customerInfoEdit = [Auth.CUSTOMER_INFO_EDIT];
export const customerNoteRead = [Auth.CUSTOMER_NOTE_READ];
export const customerNoteEdit = [Auth.CUSTOMER_NOTE_EDIT];
export const customerAvailableCourseRead = [
  Auth.CUSTOMER_AVAILABLE_COURSE_READ,
  Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_READ,
  Auth.CUSTOMER_CONTRACT_READ,
  Auth.CUSTOMER_CONTRACT_RECORD_READ,
];
export const customerAvailableCourseEdit = [
  Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_EDIT,
  Auth.CUSTOMER_CONTRACT_RECORD_EDIT,
];
export const customerBillRead = [Auth.CUSTOMER_BILL_READ];
export const customerBillEdit = [Auth.CUSTOMER_BILL_EDIT];
export const customerCourseRead = [Auth.CUSTOMER_COURSE_READ];
export const customerCourseEdit = [Auth.CUSTOMER_COURSE_EDIT];
export const customerDetail = [
  ...customerAccountRead,
  ...customerInfoRead,
  ...customerNoteRead,
  // TODO: 缺成員權限
  ...customerBillRead,
  ...customerBillEdit,
  ...customerAvailableCourseRead,
];
export const visitor = [...visiterDataRead, ...visiterDataEdit];
export const customer = [
  ...customerDataRead,
  ...customerDataEdit,
  ...customerAccountRead,
  ...customerAccountEdit,
  ...customerInfoRead,
  ...customerInfoEdit,
  ...customerNoteRead,
  ...customerNoteEdit,
  ...customerAvailableCourseRead,
  ...customerAvailableCourseEdit,
  ...customerLearningbarCreate,
];
export const customerVisitor = [...visitor, ...customer];
// #endregion
// #region 課堂管理權限
// new
export const auditionVipPass = [Auth.AUDITION_VIP_PASS_CREATE];
export const auditionRead = [
  Auth.AUDITION_BOOK_READ,
  Auth.AUDITION_SCHEDULE_READ,
];
export const auditionEdit = [
  Auth.AUDITION_SCHEDULE_CREATE,
  Auth.AUDITION_SCHEDULE_DELETE,
];
export const formalCourseRead = [
  Auth.COURSE_FORMAL_READ,
  Auth.COURSE_FORMAL_URL_READ,
];
export const formalCourseEdit = [
  Auth.COURSE_CREATE,
  Auth.COURSE_FORMAL_EDIT,
  Auth.COURSE_FORMAL_DELETE,
];
export const formalCourseCreate = [Auth.COURSE_CREATE, Auth.COURSE_FORMAL_READ];
export const courseRead = [
  Auth.COURSE_FORMAL_READ,
  Auth.COURSE_AUDITION_READ,
  Auth.COURSE_AUDITION_OWN_READ,
];
export const courseCreate = [Auth.COURSE_CREATE];
export const courseEdit = [Auth.COURSE_FORMAL_EDIT, Auth.COURSE_AUDITION_EDIT];
export const courseAuditionRead = [
  Auth.COURSE_AUDITION_READ,
  Auth.COURSE_AUDITION_OWN_READ,
  Auth.COURSE_AUDITION_URL_READ,
];
export const realtimeCourseRead = [Auth.COURSE_OBSERVATION_READ];
export const courseFormalAuditionRead = [
  ...formalCourseRead,
  ...courseAuditionRead,
];
export const courseAuditionEdit = [
  Auth.COURSE_AUDITION_EDIT,
  Auth.COURSE_AUDITION_DELETE,
];
export const courseDataExport = [Auth.COURSE_DATA_EXPORT];
export const afterClassStudyRead = [
  Auth.AFTER_CLASS_STUDY_READ,
  Auth.AFTER_CLASS_STUDY_EXPORT,
];
export const afterClassStudyEdit = [
  Auth.AFTER_CLASS_STUDY_CREATE,
  Auth.AFTER_CLASS_STUDY_EDIT,
  Auth.AFTER_CLASS_STUDY_CANCEL,
];
export const courseTagRead = [Auth.COURSE_TAG_READ];
export const courseTagEdit = [Auth.COURSE_TAG_IMPORT, Auth.COURSE_TAG_DELETE];
// old
export const auditionPeriod = [
  Auth.AUDITION_PERIOD_READ,
  Auth.AUDITION_PERIOD_CREATE,
  Auth.AUDITION_PERIOD_EDIT,
  Auth.AUDITION_PERIOD_DELETE,
];
export const auditionReservation = [
  Auth.AUDITION_RESERVATION_READ,
  Auth.AUDITION_RESERVATION_CREATE,
];
export const auditionCourseCreate = [Auth.AUDITION_COURSE_CREATE];
export const auditionCourseRead = [Auth.AUDITION_COURSE_READ];
export const auditionPublicCourseCreate = [Auth.AUDITION_PUBLIC_COURSE_CREATE];
export const auditionPublicCourseRead = [Auth.AUDITION_PUBLIC_COURSE_READ];
export const auditionCourseEdit = [
  Auth.AUDITION_COURSE_EDIT,
  Auth.AUDITION_COURSE_CANCEL,
];
export const auditionPublicCourseEdit = [
  Auth.AUDITION_PUBLIC_COURSE_EDIT,
  Auth.AUDITION_PUBLIC_COURSE_CANCEL,
];
export const auditionCourse = [
  Auth.AUDITION_COURSE_LINK,
  ...auditionCourseCreate,
  ...auditionCourseRead,
  ...auditionCourseEdit,
];
export const auditionPublicCourse = [
  Auth.AUDITION_PUBLIC_COURSE_LINK,
  ...auditionPublicCourseCreate,
  ...auditionPublicCourseRead,
  ...auditionPublicCourseEdit,
];
// new auth
export const auditionFormalCourse = [...formalCourseRead, ...formalCourseEdit];
export const courseAudition = [...courseAuditionRead, ...courseAuditionEdit];
export const auditionBookSchedule = [...auditionRead, ...auditionEdit];
export const afterClassStudy = [...afterClassStudyRead, ...afterClassStudyEdit];
export const corseTag = [...courseTagRead, ...courseTagEdit];

export const auditionFormalCourseRead = [
  ...auditionVipPass,
  ...formalCourseRead,
  ...courseAuditionRead,
  ...auditionRead,
  ...afterClassStudyRead,
  ...courseTagRead,
];

export const audition = [
  ...auditionVipPass,
  ...auditionBookSchedule,
  ...auditionFormalCourse,
  ...afterClassStudy,
  ...corseTag,
  // ...auditionPeriod,
  // ...auditionReservation,
  // ...auditionCourse,
  // ...auditionPublicCourse,
];
// #endregion
// #region 補習班課程管理權限
export const tutorialCenterAuditionRead = [
  Auth.TUTORIAL_CENTER_AUDITION_BOOK_READ,
  Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_READ,
];
export const tutorialCenterAuditionEdit = [
  Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_CREATE,
  Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_DELETE,
];
export const tutorialCenterFormalCourseRead = [
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_READ,
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_URL_READ,
];
export const tutorialCenterFormalCourseEdit = [
  Auth.TUTORIAL_CENTER_COURSE_CREATE,
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_EDIT,
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_DELETE,
];
export const tutorialCenterAuditionCourseRead = [
  Auth.TUTORIAL_CENTER_COURSE_AUDITION_READ,
  Auth.TUTORIAL_CENTER_COURSE_AUDITION_OWN_READ,
  Auth.TUTORIAL_CENTER_COURSE_AUDITION_URL_READ,
];
export const tutorialCenterAuditionCourseEdit = [
  Auth.TUTORIAL_CENTER_COURSE_AUDITION_EDIT,
  Auth.TUTORIAL_CENTER_COURSE_AUDITION_DELETE,
];
export const tutorialCenterCourseDataExport = [
  Auth.TUTORIAL_CENTER_COURSE_DATA_EXPORT,
];
export const tutorialCenterRead = [
  // ...tutorialCenterAuditionCourseRead, // TODO: 補習班試聽沒實作，先不判斷
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_READ,
  // ...tutorialCenterAuditionRead, // TODO: 補習班試聽行事曆沒實作，先不判斷
];
export const tutorialCenterCourseRead = [
  // ...tutorialCenterAuditionCourseRead, // TODO: 補習班試聽沒實作，先不判斷
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_READ,
];
// #endregion
// #region 訂單管理權限
export const electroniccommerceOrderListRead = [
  Auth.ELECTRONICCOMMERCE_ORDER_READ,
];
export const electroniccommerceOrderListEdit = [
  Auth.ELECTRONICCOMMERCE_ORDER_EXPORT,
  Auth.ELECTRONICCOMMERCE_ORDER_CREATE,
];
export const orderDetailsEdit = [
  Auth.ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_CANCEL,
  Auth.ELECTRONICCOMMERCE_ORDER_CLOSE,
  Auth.ELECTRONICCOMMERCE_ORDER_EMAIL,
  Auth.ELECTRONICCOMMERCE_ORDER_INVOICE,
  Auth.ELECTRONICCOMMERCE_ORDER_FUND,
  Auth.ELECTRONICCOMMERCE_ORDER_REFUND,
];
export const electroniccommerceLearningbarOrderListEdit = [
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE,
];
export const electroniccommerceCommodityRead = [
  Auth.ELECTRONICCOMMERCE_COMMODITY_READ,
];
export const electroniccommerceCommodityEdit = [
  Auth.ELECTRONICCOMMERCE_COMMODITY_CREATE,
  Auth.ELECTRONICCOMMERCE_COMMODITY_EDIT,
];
export const electroniccommerceCommodity = [
  Auth.ELECTRONICCOMMERCE_COMMODITY_READ,
  Auth.ELECTRONICCOMMERCE_COMMODITY_CREATE,
  Auth.ELECTRONICCOMMERCE_COMMODITY_EDIT,
];
export const electroniccommerceSettingRead = [
  Auth.ELECTRONICCOMMERCE_SETTING_READ,
];
export const electroniccommerceSettingEdit = [
  Auth.ELECTRONICCOMMERCE_SETTING_EDIT,
];
export const electroniccommerceSetting = [
  Auth.ELECTRONICCOMMERCE_SETTING_READ,
  Auth.ELECTRONICCOMMERCE_SETTING_EDIT,
];

export const electroniccommerceOrder = [
  Auth.ELECTRONICCOMMERCE_ORDER_READ,
  Auth.ELECTRONICCOMMERCE_ORDER_EXPORT,
  Auth.ELECTRONICCOMMERCE_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_CANCEL,
  Auth.ELECTRONICCOMMERCE_ORDER_CLOSE,
  Auth.ELECTRONICCOMMERCE_ORDER_EMAIL,
  Auth.ELECTRONICCOMMERCE_ORDER_INVOICE,
  Auth.ELECTRONICCOMMERCE_ORDER_FUND,
  Auth.ELECTRONICCOMMERCE_ORDER_REFUND,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE,
];

export const electroniccommerce = [
  ...electroniccommerceCommodity,
  ...electroniccommerceSetting,
  ...electroniccommerceOrder,
];
// #endregion
// #region 權限管理權限
export const authorityEmployee = [
  Auth.AUTHORITY_EMPLOYEE_READ,
  Auth.AUTHORITY_EMPLOYEE_EDIT,
];

export const authorityRole = [
  Auth.AUTHORITY_ROLE_READ,
  Auth.AUTHORITY_ROLE_EDIT,
];

export const authorityDepartment = [
  Auth.AUTHORITY_DEPARTMENT_READ,
  Auth.AUTHORITY_DEPARTMENT_EDIT,
];

export const authority = [
  ...authorityEmployee,
  ...authorityRole,
  ...authorityDepartment,
];
// #endregion

// #region 教務管理權限
export const teacherListRead = [Auth.TEACHER_READ];
export const teacherListEdit = [
  Auth.TEACHER_CREATE,
  Auth.TEACHER_EDIT,
  Auth.TEACHER_SCHEDULE_CREATE,
  Auth.TEACHER_SCHEDULE_DELETE,
  Auth.TEACHER_SCHEDULE_LEAVE,
];
export const teacherMaterialRead = [Auth.MATERIAL_READ];

export const teacherMaterialEdit = [
  Auth.MATERIAL_CREATE,
  Auth.MATERIAL_EDIT,
  Auth.MATERIAL_IMPORT,
  Auth.MATERIAL_DELETE,
  Auth.MATERIAL_EXAM,
];

export const teacherListMaterialRead = [
  ...teacherListRead,
  ...teacherMaterialRead,
];
export const teacherList = [...teacherListRead, ...teacherListEdit];
export const teacherMaterial = [...teacherMaterialRead, ...teacherMaterialEdit];
export const teachersmanagement = [...teacherList, ...teacherMaterial];
export const teacherApplicationRead = [Auth.TEACHER_APPLICATION_READ];
export const teacherApplicationEdit = [
  Auth.TEACHER_APPLICATION_REVIEW_INIT,
  Auth.TEACHER_APPLICATION_REVIEW_SECOND,
];

export const teacherApplicationReadUnverified = [
  Auth.TEACHER_APPLICATION_READ_UNVERIFIED,
];
// #endregion

// #region 統計報表權限
// 業績瀏覽 - 查看
export const reportPerformanceRead = [
  Auth.PERFORMANCE_ALL_READ,
  Auth.PERFORMANCE_OWN_READ,
  Auth.PERFORMANCE_EXPORT,
];
// 業績瀏覽 - 編輯
export const reportPerformanceEdit = [Auth.PERFORMANCE_EDIT];
// #endregion
// #region 統計報表權限
export const reportDaily = [Auth.REPORT_DAILY_READ];
export const reportRealtime = [Auth.REPORT_REALTIME_READ];
export const report = [
  ...reportDaily,
  ...reportRealtime,
  ...reportPerformanceRead,
];
// #endregion

// #region 財務管理權限
export const teacherSalaryRead = [Auth.TEACHER_SALARY_READ];
export const teacherSalaryEdit = [
  Auth.TEACHER_SALARY_EDIT,
  Auth.TEACHER_SALARY_EXPORT,
];
export const salaryOverallRead = [Auth.SALARY_OVERALL_READ];
export const salaryOverallEdit = [
  Auth.SALARY_OVERALL_EDIT,
  Auth.SALARY_OVERALL_EXPORT,
];
export const laborCostRead = [Auth.LABOR_COST_READ];
export const laborCostExport = [Auth.LABOR_COST_EXPORT];

export const laborCost = [...laborCostRead, ...laborCostExport];
export const teacherSalary = [...teacherSalaryRead, ...teacherSalaryEdit];
export const salaryOverall = [...salaryOverallRead, ...salaryOverallEdit];

export const revenueRead = [Auth.REVENUE_READ];
export const revenueEdit = [Auth.REVENUE_EXPORT];
export const revenue = [...revenueRead, ...revenueEdit];
export const financial = [
  ...teacherSalary,
  ...salaryOverall,
  ...revenue,
  ...laborCost,
];

export const financialRead = [
  ...teacherSalaryRead,
  ...salaryOverallRead,
  ...laborCostRead,
  ...revenueRead,
];

// #endregion

// #region 獎章中心權限
export const badgeRead = [Auth.BADGE_READ];
export const badgeEdit = [Auth.BADGE_EDIT];

export const textbookRead = [Auth.TEXTBOOK_READ];

export const textbookCreate = [Auth.TEXTBOOK_CREATE];

export const textbookReadInfo = [Auth.TEXTBOOK_READ_INFO];

export const textbookEdit = [Auth.TEXTBOOK_EDIT];

export const textbookOverrallRead = [
  Auth.TEXTBOOK_READ,
  Auth.TEXTBOOK_READ_INFO,
  Auth.TEXTBOOK_READ_MEMBER,
  Auth.TEXTBOOK_READ_MEMBER_ALL,
];

export const textbookOverrallEdit = [
  Auth.TEXTBOOK_CREATE,
  Auth.TEXTBOOK_EDIT,
  Auth.TEXTBOOK_EXPORT,
  Auth.TEXTBOOK_IMPORT,
];

export const badgeCentralRead = [...badgeRead, ...textbookRead];

export const badgeCentral = [
  ...badgeRead,
  ...badgeEdit,
  ...textbookOverrallRead,
  ...textbookEdit,
];

// #endregion
