import { Outlet } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import { Header } from "./header";
import { Box, Typography } from "@mui/material";

const DashBoard = () => {
  const { t } = useTranslation("dashBoard");
  return (
    <Box
      sx={{
        padding: "15px",
      }}
    >
      <Header />
      <br />
      <Typography variant="h2">{t("titleDashBoard")}</Typography>
      <br />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
export default DashBoard;
