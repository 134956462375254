import { useEffect, useState } from "react";
import { useParams } from "react-router";
import TaskAPI, { Task } from "api/Task";
import StateViewAPI, { TaskWithState, StateView } from "api/StateView";
import { useNavigate } from "react-router-dom";
import { Route } from "routes/path";
import { TreeTask } from "utils/treeTask";
import TaskNode from "../TreeNode/TaskNode";
import { TreeTaskProvider } from "services/TreeTaskService";
import {
  DISABLE_DEBUG_TASK_TREE,
  Phrase,
  PlaceType,
  getPlaceLogFunc,
} from "src/utils/debugLog";
import { PageHeader, PagePager } from "components/pages/PageStyle.style";
import { Box, Button, useTheme } from "@mui/material";

function TaskTree() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [stateViewId, setStateViewId] = useState<string>("");
  const [taskData, setTaskData] = useState<Task | null>(null);
  const [treeData, setTreeData] = useState<TreeTask | null>(null);

  const logger = getPlaceLogFunc(
    PlaceType.Component,
    "TaskTree",
    DISABLE_DEBUG_TASK_TREE,
  );

  const getStateView = async () => {
    const stateViewPaging = await StateViewAPI.paging(1, 10, "");
    const rows = stateViewPaging.rows;
    if (rows && rows.length > 0) {
      return rows[0];
    }
    return undefined;
  };

  const loadInitData = async (rootNodeId: string) => {
    const stateView = await getStateView();
    if (stateView) {
      const taskData = await StateViewAPI.getNode(rootNodeId, stateView.id);
      setTaskData(() => taskData);

      const data = await TaskAPI.getChildren(taskData.id);
      const tree = new TreeTask(taskData.id, [taskData, ...data]);
      setTreeData(() => tree);
    }
  };

  // const handleSaveCurrentState

  useEffect(() => {
    if (!id) {
      navigate(Route.notFound, { replace: true });
      return;
    }
    try {
      const rootNodeId = id;
      loadInitData(rootNodeId);
    } catch (err) {
      navigate(Route.notFound, { replace: true });
      return;
    }
  }, [id]);

  const handleOnTreeChange = (tree: TreeTask) => {
    logger(Phrase.EventTrigger, {
      tree,
    });
  };

  logger(Phrase.Render, {
    taskData,
    treeData,
  });
  const theme = useTheme();
  window.console.log("theme", theme.palette.primary);

  return (
    <PagePager sx={{ width: "100%" }}>
      {treeData && (
        <TreeTaskProvider
          params={{ tree: treeData, onChange: handleOnTreeChange }}
        >
          <PageHeader
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            task with id{id}
            <Button variant="contained">Save the current state</Button>
          </PageHeader>

          {treeData.rootNode && (
            <Box
              sx={{
                borderRight: `3px solid ${theme.palette.grey[500]}`,
                width: "auto",
                display: "inline-block",
              }}
            >
              <TaskNode key={treeData.rootId} nodeId={treeData.rootId} />
            </Box>
          )}
        </TreeTaskProvider>
      )}
    </PagePager>
  );
}

export default TaskTree;
