import { ReactComponent as Trash } from "assets/svg/trash.svg";
import { ReactComponent as AddSchedule } from "assets/svg/add-schedule.svg";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";
import { ReactComponent as AddCircle } from "assets/svg/add-circle.svg";
import { ReactComponent as TickSquare } from "assets/svg/tick-square.svg";
import { SvgIcon, SxProps } from "@mui/material";

const SVG = {
  Trash,
  AddSchedule,
  ArrowRight,
  AddCircle,
  TickSquare,
};

export enum SvgName {
  Trash = "Trash",
  AddSchedule = "AddSchedule",
  ArrowRight = "ArrowRight",
  AddCircle = "AddCircle",
  TickSquare = "TickSquare",
}

type Props = {
  name: SvgName;
  sx?: SxProps;
};

const getDefaultSx = (input?: SxProps) => {
  const defaultSx: SxProps = {
    color: "transparent",
  };
  return {
    ...defaultSx,
    ...input,
  };
};

export const Icon = ({
  name,
  sx = { width: "24px", height: "24px" },
}: Props) => {
  return (
    <SvgIcon
      inheritViewBox={true}
      component={SVG[name]}
      sx={getDefaultSx(sx)}
    />
  );
};
