import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import { getUserLanguagePreference } from '../utils/i18n';

import en from './en.json';
import vi from './vi.json';

const options = {
  order: ['navigator'],
  lookupQuerystring: 'lng',
};

const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
};

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      skipOnVariables: false,
    },
    resources,
    detection: options,
    debug: false,
    lng: getUserLanguagePreference(),
    fallbackLng: 'en',
  });

export default i18next;
