import { RouteObject, useRoutes } from "react-router-dom";
import DashBoard from "layouts/dashboard";
import LoginPage from "pages/LoginPage";
import { rewindRoutes } from "./rewind";
import { taskRoutes } from "./task";
import { themeTestingRoutes } from "./themeTesting";
import NotFoundPage from "pages/NotFoundPage";

const Routes = () => {
  const routes: RouteObject[] = [
    {
      path: "/",
      element: <DashBoard />,
      children: [...rewindRoutes, ...taskRoutes, ...themeTestingRoutes],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/404",
      element: <NotFoundPage />,
    },
  ];
  return useRoutes(routes);
};

export default Routes;
