import { Typography } from "@mui/material";

const NotFoundPage = () => {
  return (
    <>
      <Typography variant="h3">Not fucking found</Typography>
    </>
  );
};

export default NotFoundPage;
