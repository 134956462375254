import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import AuthAPI from "api/Auth";
import { AxiosResponse, HttpStatusCode } from "axios";

type Props = {
  children: ReactNode;
};

type LoginUserInfo = {
  email: string;
  role: string;
};

type AuthContextValue = {
  currentUser: LoginUserInfo | null;
  getUser: () => Promise<LoginUserInfo | null>;
  login: (
    username: string,
    password: string,
  ) => Promise<AxiosResponse<any, any>>;
  logOut: () => Promise<AxiosResponse<any, any>>;
  signUp: (
    username: string,
    password: string,
  ) => Promise<AxiosResponse<any, any>>;
  // doGoogleLogin: () => Promise<AxiosResponse<any, any>>,
};

const AuthContext = createContext<AuthContextValue | null>(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: Props) {
  const [currentAuthValue, setCurrentAuthValue] = useState<AuthContextValue>({
    currentUser: null,
    getUser,
    login,
    logOut,
    signUp,
  });

  // must true at first to prevent quick rendering of <App/>
  const [loading, setLoading] = useState(true);
  // console.log("rerender AuthProvider")

  useEffect(() => {
    // TODO : FIX THIS or check this
    setLoading(true);
    // console.log("LOADING ON")
    getUser().finally(() => {
      setLoading(false);
    });
  }, [currentAuthValue?.currentUser?.email]);

  function setCurrentUser(data: LoginUserInfo | null) {
    setCurrentAuthValue((pre) => {
      return { ...pre, currentUser: data };
    });
  }

  function login(email: string, password: string) {
    const result = AuthAPI.login(email, password);
    return result;
  }

  async function logOut() {
    const result = await AuthAPI.logout();
    if (result.status === HttpStatusCode.Ok && result.data?.success) {
      setCurrentUser(null);
    }
    return result;
  }

  function signUp(email: string, password: string) {
    const result = AuthAPI.register(email, password);
    return result;
  }

  async function getUser() {
    try {
      const result = await AuthAPI.getLoginUser();
      if (result.status === HttpStatusCode.Ok && result.data?.success) {
        const data = result.data.data as {
          email: string;
          role: string;
        };

        console.log("fetch user data from user -> auth context", data);
        setCurrentUser(data);
      }
    } catch (err) {
      // console.log("[FAIL] fetch user data from user -> auth context")
      setCurrentUser(null);
    }

    const currentValue = currentAuthValue as AuthContextValue | null;

    return currentValue ? currentValue.currentUser : null;
  }

  // function doGoogleLogin() {
  //   const result = AuthAPI.doGoogleLogin();
  //   // console.log(result);
  //   return result;
  // }

  // console.log("value", currentAuthValue)

  return (
    <AuthContext.Provider value={currentAuthValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
