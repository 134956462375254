import { ReactNode, createContext, useContext, useState } from "react";
import TaskAPI, { ICreateTask } from "api/Task";
import { TreeTaskNode } from "types/task/TaskNode";
import {
  DISABLE_DEBUG_TASK_TREE_SERVICE,
  Phrase,
  PlaceType,
  getPlaceLogFunc,
} from "utils/debugLog";
import { TreeTask } from "utils/treeTask";

type TreeTaskProviderParams = {
  tree: TreeTask;
  onChange: (tree: TreeTask) => void;
};
type TreeTaskProviderProps = {
  children: ReactNode;
  params: TreeTaskProviderParams;
};

interface IUseTreeTask {
  onChange: (tree: TreeTask) => void;
  updateNode: (node: TreeTaskNode) => Promise<TreeTaskNode>;
  addNode: (node: ICreateTask) => Promise<TreeTaskNode>;
  tree: TreeTask | null;
  treeNodes: TreeTaskNode[];
  isLoading: boolean;
}

const useTreeTask = (
  tree: TreeTask,
  onChange: (tree: TreeTask) => void,
): IUseTreeTask => {
  const [treeNodes, setTreeNodes] = useState(tree?.treeNodes ?? []);

  const logger = getPlaceLogFunc(
    PlaceType.Context,
    "useTreeTask",
    DISABLE_DEBUG_TASK_TREE_SERVICE,
  );

  const publicUpdate = () => {
    setTreeNodes([...(tree.treeNodes ?? [])]);
  };

  const updateNode = (node: TreeTaskNode) => {
    tree.updateNode(node);
    logger(
      Phrase.Function,
      { node, treeNodes: tree.treeNodes },
      "updateNode => publicUpdate",
    );
    publicUpdate();

    return Promise.resolve(node);
  };

  const addNode = async (newTaskData: ICreateTask) => {
    // TODO: call api to update node data
    const newTask = await TaskAPI.create(newTaskData);

    const newNode: TreeTaskNode = {
      ...newTask,
      children: [],
      data: newTask,
      state: TreeTask.DEFAULT_NODE_STATE,
    };

    tree.addNode(newNode);
    logger(
      Phrase.Function,
      { treeNodes: tree.treeNodes },
      "addNode => publicUpdate",
    );
    publicUpdate();

    return newNode;
  };

  logger(
    Phrase.Render,
    {
      tree,
      treeNodes,
    },
    "service rerender ???",
  );

  return {
    onChange,
    updateNode,
    addNode,
    tree,
    treeNodes,
    isLoading: false,
  };
};

function createService<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...args: any[]) => T,
  initialValue: T | undefined = undefined,
) {
  return createContext(initialValue as T);
}

const TaskTreeService = createService(useTreeTask);

export const TreeTaskProvider: React.FC<TreeTaskProviderProps> = ({
  children,
  params: { tree, onChange },
}: TreeTaskProviderProps) => {
  return (
    <TaskTreeService.Provider value={useTreeTask(tree, onChange)}>
      {children}
    </TaskTreeService.Provider>
  );
};

export const useTaskTreeService = () => useContext(TaskTreeService);

// what is the point of maintaining a single tree data structure instance like this
// what exactly happen when context value change ?
