export enum UNIVERSAL_ROUTE {
  ROOT = '/',
  OTHER = '*',
  NOT_FOUND = '404',
  LOGIN = 'login',
  REGISTER = 'register',
  ID = ':id',
}

export enum FIRST_ROUTE {
  SCHEDULE = 'rewind',
  TASK = 'task',
  THEME_TESTING = 'theme-testing',
  PROFILE = 'profile',
  NOTIFICATION = 'notification',
}
export enum FIRST_ROUTE_NAME {
  SCHEDULE = 'Rewind',
  THEME_TESTING = 'Theme testing',
  PROFILE = 'Profile',
  NOTIFICATION = 'Notification',
}

export enum SECOND_ROUTE {
  ASK = 'ask',
  LIST = 'list',
  FEEDBACK = 'feedback',
  APPLICATION = 'record',
  TRIAL_INFORMATION = 'trialInformation',
  APPLICATION_STATUS = 'status',
}
export enum SECOND_ROUTE_NAME {
  ASK = 'Ask',
  list = 'List',
  FEEDBACK = 'Feedback',
  APPLICATION = 'Application record',
  TRIAL_INSTRUCTIONS = 'Trial instructions',
  APPLICATION_STATUS = 'Application status',
}
export enum THIRD_ROUTE {
  CREATE = 'create',
}
export enum THIRD_ROUTE_NAME {
  CREATE = 'Create',
}

export const concatPath = (paths: string[]) =>
  `${UNIVERSAL_ROUTE.ROOT}${paths.join('/')}`;
export const PATH = {
  ROOT: concatPath([]),
  OTHER: concatPath([UNIVERSAL_ROUTE.OTHER]),
  NOT_FOUND: concatPath([UNIVERSAL_ROUTE.NOT_FOUND]),
  LOGIN: concatPath([UNIVERSAL_ROUTE.LOGIN]),
  REGISTER: concatPath([UNIVERSAL_ROUTE.REGISTER]),
  REWIND: concatPath([FIRST_ROUTE.SCHEDULE]),
  TASK: concatPath([FIRST_ROUTE.TASK]),
  THEME_TESTING: concatPath([FIRST_ROUTE.THEME_TESTING]),
};
