import { Box, Paper, styled } from "@mui/material";

const paddingLeftToParent = 30;
const taskNodeMaxWidth = 250;

export const TaskNodeContainer = styled(Paper)(({ theme }) => ({
  border: `3px solid ${theme.palette.grey[500]}`,
  borderRight: "none",
  minWidth: taskNodeMaxWidth,
  padding: "12px",
  paddingBottom: "20px",
  paddingRight: "0",
  display: "inline-flex",
  flexDirection: "column",
  borderRadius: "6px 0 0 6px",
}));

export const TaskNodeContentContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  marginBottom: "10px",
  display: "flex",
  justifyContent: "space-between",
}));

export const TaskNodeDescriptionContainer = styled(Box)(() => ({
  // border: "2px solid red",
  display: "flex",
}));

export const TaskNodeActionsContainer = styled(Box)(() => ({
  // border: "2px solid red",
  display: "flex",
}));

export const TaskNodeChildrenContainer = styled(Box)(() => ({
  // border: "2px solid red",
  paddingLeft: `${paddingLeftToParent}px`,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));
