import { api } from "./API_CONST";

async function login(username: string, password: string) {
  const result = await api.post(
    "/login",
    {
      username,
      password,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  );

  // optimize later, by return user id when login
  // const user = (await getLoginUser()).data.user;
  return result;
}

async function register(username: string, password: string) {
  return api.post(
    "/register",
    {
      username,
      password,
    },
    {
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded'
      // }
    },
  );
}

async function logout() {
  return api.post("/auth/logout").then((res) => {
    return res;
  });
}

async function getLoginUser() {
  return api.get("/auth/getLoginUser");
}

// async function doGoogleLogin() {
//   // expect server will return a redirect with set secure-cookies with jwt - which use to identify user
//   const result = api.get('/auth/google');
//   return result;
// }

export default { login, register, getLoginUser, logout };
