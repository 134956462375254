import { api } from "./API_CONST";

interface Task {
  id: string;
  parentId: string;
  content: string;
  stateId: boolean;
  authorId: string;
  createdAt: string;
}
export interface TaskNodeState {
  id: string;
  isExpand: boolean;
}

interface TaskPagingResponse {
  rows: Task[];
  totalRows: number;
}

export interface ICreateTask {
  content: string;
  parentId?: string;
}

async function paging(from: number, to: number, search: string) {
  const response = await api.get(
    `/task/paging?from=${from}&to=${to}&search=${search}`,
  );
  const result = response.data as TaskPagingResponse;
  return result;
}

async function get(id: string) {
  const response = await api.get(`/task/${id}`);
  const result = response.data as Task;
  return result;
}

async function getChildren(parentId: string) {
  const response = await api.get(`/task/children/${parentId}`);
  const result = response.data as Task[];
  return result;
}

async function create(newTask: ICreateTask) {
  const response = await api.post(
    "/task",
    {
      content: newTask.content,
      parentId: newTask.parentId,
    },
    {
      // TODO: FIX THIS, WHY WHE NEED THIS
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  );
  const result = response.data as Task;
  return result;
}

async function update(ID: string, completed: boolean) {
  return api.patch("/task/" + ID, { completed });
}

// async function update(ID: string, name: string) {
//   return api.patch('/tasks/' + ID, { name: name });
// }

async function remove(ID: string) {
  return api.delete("/task/" + ID);
}

export type { Task };

const TaskAPI = {
  get,
  paging,
  getChildren,
  update,
  create,
  remove,
};

export default TaskAPI;
