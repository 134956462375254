import { AuthProvider, useAuth } from "context/AuthContext";

import { TranslationProvider } from "services/TranslationService";
import ThemeProvider from "theme/ThemeProvider";
import Routes from "./routes";
function App() {
  console.log("render App");
  return (
    <TranslationProvider>
      <AuthProvider>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </AuthProvider>
    </TranslationProvider>
  );

  // <>
  //     <div className="page-wrapper">
  //         <Routes>
  //             <Route
  //                 path="/"
  //                 element={
  //                     <PrivateWrapper>
  //                         <HomePage />
  //                     </PrivateWrapper>
  //                 }
  //             />

  //             <Route
  //                 path="/task-tree/:id"
  //                 element={
  //                     <PrivateWrapper>
  //                         <TaskTree />
  //                     </PrivateWrapper>
  //                 }
  //             />

  //             <Route
  //                 path="/rewind"
  //                 element={
  //                     <PrivateWrapper>
  //                         <RewindPage />
  //                     </PrivateWrapper>
  //                 }
  //             />
  //             <Route
  //                 path="/theme-testing"
  //                 element={<ThemeTestingPage />}
  //             />

  //             <Route path="/login" element={<LoginPage />} />
  //         </Routes>
  //     </div>
  // </>
}

export default App;
