export const getGoogleUrl = (from: string) => {
  const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";

  const redirect_uri = import.meta.env.VITE_GOOGLE_OAUTH_REDIRECT as string;
  const client_id = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID as string;

  window.console.log("redirect_uri", redirect_uri);

  const options = {
    redirect_uri,
    client_id,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    state: from,
  };

  const qs = new URLSearchParams(options);

  console.log(`link href: ${rootUrl}?${qs.toString()}`);

  return `${rootUrl}?${qs.toString()}`;
};
