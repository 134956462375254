export enum PlaceType {
  Context = "context",
  Event = "event-handler",
  Component = "component",
  Function = "function",
}

export enum Phrase {
  Render = "render",
  UseEffect = "useEffect",
  EventTrigger = "event-trigger",
  Constructor = "constructor",
  Function = "function",
}

import _ from "lodash";

export const cLog = (
  type: PlaceType,
  phrase: Phrase,
  object: object | string,
  prefix: string = "",
) => {
  const noRefObject = _.cloneDeep(object);
  window.console.log(`cLog: [${type}][${phrase}] ${prefix}`, noRefObject);
};

export const getPlaceLogFunc = (
  placeType: PlaceType,
  prefix?: string,
  disable = false,
  // defaultLog: string | object = {},
) => {
  return (phrase: Phrase, object: object | string, description?: string) => {
    if (disable) {
      return;
    }
    let nextPrefix = prefix ?? "";
    if (description) {
      nextPrefix += " " + description;
    }

    cLog(placeType, phrase, object, nextPrefix);
  };
};

// component
export const DISABLE_DEBUG_TASK_TREE = true;
export const DISABLE_DEBUG_TASK_NODE = true;
// service
export const DISABLE_DEBUG_TASK_TREE_SERVICE = false;
// util data structure
export const DISABLE_DEBUG_TASK_TREE_UTILS = true;

// I WANT
// 1) quickly remove all console.log of a component
// 2) all way console.log with NO REF
// 3) do not repeatedly log the place, where ....
