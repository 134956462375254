import { t as trans } from 'i18next';

const t = (
  key: string,
  defaultValue: string,
  params: any | null = {},
): string => {
  return (trans(key, { ...params, defaultValue }) as unknown) as string || '';
};

const getUserLanguagePreference = () => {
  const lang = localStorage.getItem('lang');
  return lang || 'en'; // return 'zh' as the default language if no language preference is stored
};

export { t, getUserLanguagePreference };
